import React, { useRef, useEffect } from "react";
import { withAssetPrefix } from "gatsby";
import IconButton from "components/IconButton";
import "components/SpeechBubble.scss";

export default function SpeechBubble({ text, src, disabled = false, isPlaying, onAudioClick }) {
	const player = useRef();

	// Listener for audio ended
	useEffect(() => {
		const endedListener = onAudioClick;
		const playerCurrent = player.current;
		playerCurrent.addEventListener("ended", endedListener);
		return () => playerCurrent.removeEventListener("ended", endedListener);
	}, [player, onAudioClick]);

	// Start/stop playback depending on isPlaying
	useEffect(() => {
		if (isPlaying) {
			player.current.src = withAssetPrefix(src);
			player.current.play();
		}
		else {
			player.current.pause();
			player.current.currentTime = 0;
		}
	}, [src, isPlaying, player]);

	return (
		<div className="SpeechBubble">
			<div>
				<p>{text}</p>
				<IconButton
					onClickCapture={onAudioClick}
					disabled={disabled}
				>
					{`Play audio of: ${text}`}
				</IconButton>
				<audio ref={player} preload="none" src={withAssetPrefix(src)} />
			</div>
		</div>
	);
}