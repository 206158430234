import React, { useState, useEffect, useCallback, useRef } from "react";
import { Helmet } from "react-helmet";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import IconButton from "components/IconButton";
import LanguageCard from "components/LanguageCard";
import Layout from "components/Layout";
import Title from "components/Title";
import "pages/discover.scss";

export default function DiscoverPage() {
	const { t } = useTranslation();
	const { language } = useI18next();
	const crumbs = [
		{ pathname: `/${language}`, label: "home" },
		{ pathname: `/${language}/discover`, label: "discover.title" }
	];

	// The audio code currently playing
	const [playing, setPlaying] = useState(undefined);

	// STATES
	const [type, setType] = useState("dialogues");
	
	// Original
	const [originalLanguage, setOriginalLanguage] = useState(language);
	const [originalItems, setOriginalItems] = useState([]);
	
	// Target
	const [targetLanguage, setTargetLanguage] = useState("default");
	const [targetItems, setTargetItems] = useState([]);
	
	// Handle audio click
	const onAudioChange = useCallback(audioCode => {
		setPlaying(prevAudioCode =>
			prevAudioCode !== audioCode
			? audioCode
			: undefined
			);
		}, []);
		

	// EFFECTS
	// Import translation items
	useEffect(() => {
		if (originalLanguage === "default") {
			import(`locales/cards/${type}/${type}.en.js`).then(res => setOriginalItems(res.default));
		} else {
			import(`locales/cards/${type}/${type}.${originalLanguage}.js`).then(res => setOriginalItems(res.default));
		}
	}, [originalLanguage, type]);

	useEffect(() => {
		if (targetLanguage === "default") {
			import(`locales/cards/${type}/${type}.en.js`).then(res => setTargetItems(res.default));
		} else {
			import(`locales/cards/${type}/${type}.${targetLanguage}.js`).then(res => setTargetItems(res.default));
		}
	}, [targetLanguage, type]);


	// SLIDE
	const cardsRef = useRef();
	const onSlideButtonClick = (left) => {
		let pixels = left;
		if (left === "screen") {
			pixels =  window.innerWidth;
		} 
		cardsRef.current.scroll({left: pixels, behavior: 'smooth'});
	};


	// CARD TYPES
	const cardTypes = ["dialogues", "phrases", "numbers"];

	return (
		<Layout title={t('discover.title')} crumbs={crumbs}>
			<Helmet>
				<meta name="DCSext.cg_s_s" content="Discover the official EU languages!" />
			</Helmet>

			<Title title={t('discover.title')}></Title>

			<div className="discover-page">
				<div className="navigation">
					<nav>
						<ul>
							{cardTypes.map(cardType => (
								<li key={cardType}>
									<IconButton
										className={`${cardType} ${type === cardType ? "active" : ""}`}
										ariaCurrent={type === cardType && "page"}
										onClick={() => setType(cardType)}
									>
										{t(`discover.navigation.${cardType}`)}
									</IconButton>
									<span className="tooltip" aria-hidden="true">{t(`discover.navigation.${cardType}`)}</span>
								</li>
							))}
						</ul>
					</nav>
					<h2>{t(`discover.navigation.${type}`)}</h2>
				</div>

				<div className="cards-wrapper" ref={cardsRef}>
					<div className="cards">
						<LanguageCard
							type={type}
							language={language}
							cardLanguage={originalLanguage}
							items={originalItems}
							onLanguageSelectChange={e => setOriginalLanguage(e.target.value)}
							onSlideButtonClick={() => onSlideButtonClick("screen")}
							siblingLanguage={targetLanguage}
							playing={playing}
							audioPrefix="original"
							onAudioChange={onAudioChange}
						/>
						<LanguageCard
							type={type}
							language={language}
							cardLanguage={targetLanguage}
							items={targetItems}
							onLanguageSelectChange={e => setTargetLanguage(e.target.value)}
							onSlideButtonClick={() => onSlideButtonClick(0)}
							siblingLanguage={originalLanguage}
							playing={playing}
							audioPrefix="target"
							onAudioChange={onAudioChange}
						/>
					</div>
				</div>
			</div>
		</Layout>
	);
}

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;
