import React from "react";
import "components/IconButton.scss";

export default function IconButton({ onClick, onClickCapture, children, className, disabled = false, ariaCurrent= false }) {
	return (
		<button
			className={`IconButton ${className ?? ""}`}
			onClick={onClick}
			onClickCapture={onClickCapture}
			disabled={disabled}
			aria-current={ariaCurrent}
		>
			{children}
		</button>
	);
}