import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { cardLanguages } from "utils/card-languages";
import SpeechBubble from "components/SpeechBubble";
import "components/LanguageCard.scss";

// TODO: check browser support
var collator = new Intl.Collator(undefined, {
	numeric: true,
	sensitivity: 'base'
});

export default function LanguageCard({ type, language, cardLanguage, siblingLanguage, items, playing, audioPrefix, onLanguageSelectChange, onSlideButtonClick, onAudioChange }) {
	const { t } = useTranslation();
	const nativeName = cardLanguages[cardLanguage] ?? false;
	return (
		<div className={`LanguageCard ${type} ${cardLanguage}`}>
			<div className={`card-select ${(cardLanguage !== language && cardLanguage !== "default") ? "has-native-name" : ""}`}>
				<label>
					{"Language"}
					<select
						value={cardLanguage}
						onChange={onLanguageSelectChange}
					>
						<option value="default" disabled hidden>{t('discover.select-language.one')}</option>
						<option value={language}>{t(`discover.card-languages.${language}`)}</option>
						{
							Object.keys(cardLanguages)
								.filter(item => item !== language)
								.map(item => ({ code: item, label: t(`discover.card-languages.${item}`)}))
								.sort((l1, l2) => collator.compare(l1.label, l2.label))
								.map(item => <option key={item.code} value={item.code}>{item.label}</option>)
						}
					</select>
					{cardLanguage !== language && <span>{nativeName}</span>}
				</label>
				
				<button
					className={siblingLanguage === "default" ? "add" : "arrow"}
					onClick={onSlideButtonClick}
				>
					{siblingLanguage === "default" ? t('discover.select-language.second') : t(`discover.card-languages.${siblingLanguage}`)}
				</button>
			</div>

			<div className="card-content" aria-hidden={cardLanguage === "default"}>
				<ol className="speech-bubbles">
					{items.map(item => (
						<li key={`${item.audioCode}${cardLanguage}`}>
							<SpeechBubble
								text={item.text}
								disabled={cardLanguage === "default"}
								src={`/audio/${cardLanguage?.toUpperCase()}/${item.audioCode}_${cardLanguage?.toUpperCase()}.mp3`}
								isPlaying={playing === `${audioPrefix}_${item.audioCode}_${cardLanguage}`}
								onAudioClick={() => onAudioChange(`${audioPrefix}_${item.audioCode}_${cardLanguage}`)}
							/>
						</li>
					))}
				</ol>
			</div>
		</div>
	);
}
